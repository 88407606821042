import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-usertype',
  templateUrl: './usertype.component.html',
  styleUrls: ['./usertype.component.scss']
})
export class UsertypeComponent implements OnInit {

  user_type: string;

  constructor( private router: Router) { 
    this.user_type = localStorage.getItem('user_type');
    if (this.user_type == 'patient') {
      this.router.navigateByUrl('/patient/login');
    }
    else if (this.user_type == 'caregiver') {
      this.router.navigateByUrl('/caregiver/login');
    }

  }

  ngOnInit() {
  }

  set_user_type(user_type) {
    localStorage.setItem('user_type', user_type);
    if (user_type == 'patient') {
      this.router.navigateByUrl('/patient/login');
    }
    else {
      this.router.navigateByUrl('/caregiver/login');
    }
  }

}
