import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from './../_services/authentication.service';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'upload-confirm-patient',
    templateUrl: 'upload-confirm-libre.html',
})
export class UploadConfirmPatient {
    filepath: string;
    errorMsg: string;
    showError: boolean = false;
    data_dir: string;
    patient: any;
    isLoading: boolean = false;
    isSuccess: boolean = false;
    successMsg: string = 'Success!';
    sensor_data: any;
    patientID;
    glucose_data;
    isUploadLoading = false;

    btnOpts: MatProgressButtonOptions = {
        active: false,
        text: 'Yes',
        spinnerSize: 19,
        raised: true,
        stroked: true,
        flat: false,
        fab: false,
        buttonColor: 'primary',
        spinnerColor: 'accent',
        fullWidth: false,
        disabled: false,
        mode: 'indeterminate',
        customClass: 'some-class',
    };

    constructor(
        public dialogRef: MatDialogRef<UploadConfirmPatient>, private auth: AuthenticationService, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.sensor_data = data['sugar_data'];
        this.patientID = data['patient_id'];

        this.glucose_data = this.sensor_data['glucose'].map(sensor =>
            Object.assign({}, sensor, {
                start_timestamp: new Date(sensor['start_timestamp']).toLocaleDateString(),
                end_timestamp: new Date(sensor['end_timestamp']).toLocaleDateString()
            }))
        console.log(this.glucose_data);
    }

    onClose(): void {
        this.dialogRef.close();
    }

    uploadData(sensor) {
        console.log('data to upload ', sensor);
        this.isUploadLoading = true;

        let dataToSend = {
            // sensor_id: this.patientID,
            user_id: this.patientID,
            sugar_data: sensor['glucose'],
            source: this.sensor_data['source'],
            patient_id: this.patientID,
            timezone: 'Asia/Kolkata',
            timestamp: Date.now(),
            user_agent: 'web',
        };
        this.auth.uploadDataToV1Server(dataToSend).subscribe(
            res => {
                this.isUploadLoading = false;
                this.isLoading = false;
                this.showError = false;
                this.isSuccess = true;
                this.successMsg = 'Data uploaded successfully! Your report will be generated.'

                setTimeout(() => {
                    this.dialogRef.close({ 'closeParent': true });
                }, 1500)
            },
            error => {
                this.isUploadLoading = false;
                this.showError = true;

                if (error.status == 0 || error.status == 500) {
                    this.errorMsg = 'Something went wrong, please contact 7sugar admin!';
                }
                else {
                    this.errorMsg = error.error;
                }
                console.log(error);
            }
        );
    }

}
