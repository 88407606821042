import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from './../_services/authentication.service';
import { Router } from '@angular/router';
// var messages = require("./../proto/.js");

@Component({
  selector: 'app-login',
  templateUrl: './patient-login.component.html',
  styleUrls: ['./patient-login.component.scss']
})
export class PatientLoginComponent implements OnInit {

  loginForm: FormGroup;
  mobileForm: FormGroup;
  otpForm: FormGroup;
  loading = false;
  submitted = false;
  isDisabled = true;
  isError: boolean = false;
  errorMsg: string;
  returnUrl: string;
  loginData;
  // getOtpBtn: boolean = false;
  isPhone: boolean = true;
  isOtp: boolean = false;
  phone_number;
  country;
  // passwordRegex = '^.{6,}$';
  emailRegex = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthenticationService,
  ) { }

  ngOnInit() {
    this.mobileForm = new FormGroup({
      country: new FormControl('+91'),
      mobile: new FormControl(''),
    });

    this.otpForm = new FormGroup({
      otp: new FormControl()
    });

  }

  sendOTP(data) {
    this.phone_number = data['mobile'];
    this.country = data['country'];
    const country = this.country.replace('+', '%2B')

    this.auth.sendPatientOTP({ 'phone_number': country + this.phone_number }).subscribe(
      response => {
        console.log(response);
        this.isPhone = false;
        this.isOtp = true;
      },
      error => {
        console.log(error)
      }
    )


  }

  resendOTP() {

  }

  verifyOTP(data) {
    this.submitted = true;
    if (this.otpForm.invalid) {
      return;
    }

    this.loading = true;
    this.isError = false;
    var dataToSend;
    dataToSend = {
      "phone_number": this.country + this.phone_number,
      "auth_otp": data['otp'],
      "timestamp": Date.now(),
      "user_agent": "web"
    }

    this.auth.verifyPatientOTP(dataToSend).subscribe(
      data => {

        localStorage.setItem('user_id', data['user_id']);
        localStorage.setItem('isLogged', 'true');
        localStorage.setItem('user_type', 'patient');
        localStorage.setItem('device_id', '_');
        localStorage.setItem('auth_token', '_');

        this.router.navigateByUrl('/patient/home');
      },
      error => {
        this.isError = true;

      }
    )





    // this.auth.loginPatient(dataToSend).subscribe(
    //   data => {
    //     this.submitted = false;
    //     localStorage.setItem('user_id', data['user_id']);
    //     localStorage.setItem('device_id', data['device_id']);
    //     localStorage.setItem('auth_token', data['auth_token']);
    //     this.router.navigateByUrl('/patients');
    //   },
    //   error => {
    //     if (error.status == 400 || 401) {
    //       this.isError = true;
    //       this.errorMsg = 'Invalid credentials. Please enter correct details.'
    //       this.submitted = false;
    //       console.log(error);
    //     }
    //     else {
    //       this.isError = true;
    //       this.errorMsg = 'Something went wrong, please try again or contact admin!'
    //       this.submitted = false;
    //       console.log(error);
    //     }
    //   });

  }

}
