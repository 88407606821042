import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthenticationService {

    constructor(private http: HttpClient) {}
    localServer: string = 'http://127.0.0.1:7777/';
    serverUrl: string = 'https://beta.7sugar.com/';
    v2Url: string = 'https://api.7sugar.com/web/bridge/';
    devUrl: string = 'https://dev.7sugar.com/web/bridge/';
    // otpUrl: string = 'https://api.7sugar.com/patient/';


    // dev v1 url
    // v1Url: string = 'https://dev-helios.7sugar.com/'

    login(data) {
        return this.http.post('https://beta.7sugar.com/web/sessions/', data)
    }

    getPatients(data) {
        return this.http.post('https://beta.7sugar.com/api/sugar/patientslist', data)
    }

    readDataFromReader() {
        return this.http.get(this.localServer + 'readdata')
    }

    getDataFiles() {
        return this.http.get(this.localServer + 'localfiles')
    }

    readFromFile(name, path) {
        return this.http.get(this.localServer + 'readfile?name=' + name + '.json' + '&path=' + path)
    }

    readFromReader() {
        return this.http.get(this.localServer + 'readdata')
    }

    uploadDataToServer(data) {
        return this.http.post(this.serverUrl + 'api/sugar/uploadsugardata', data)
    }

    deleteFile(name, path) {
        return this.http.get(this.localServer + 'deletefile?name=' + name +'.json' + '&path=' + path)
    }

    loginToV1(data) {
        console.log(data);
        return this.http.post(this.v2Url + 'login', data);
    }

    loginPatient(data) {
        return data;
    }

    getV1Patients(data) {
        console.log(data);
        return this.http.get(this.v2Url + 'patientslist?user_id='+data['user_id']+'&device_id='+data['device_id']+'&slug='+data['user_token']);
    }

    uploadDataToV1Server(data) {
        return this.http.post(this.v2Url + 'uploadsugar', data)
    }

    sendPatientOTP(data) {
        return this.http.get(this.v2Url + 'send_otp?phone_number=' + data['phone_number'])
    }

    verifyPatientOTP(data) {
        return this.http.post(this.v2Url + 'verify_otp', data)
    }

    getPatientReports(data) {
        console.log(data);
        return this.http.get(this.v2Url + 'patientreportlist?user_id=' + data['user_id']);
    }

}
