import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from './../_services/authentication.service';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReportListComponent } from '../report-list/report-list.component';
import { UploadConfirmPatient } from '../upload-confirm-libre/upload-confirm-libre';

// @Component({
//   selector: 'app-patient-home',
//   templateUrl: './patient-home.component.html',
//   styleUrls: ['./patient-home.component.scss']
// })

// // import { start } from 'repl';

// @Component({
//   selector: 'confirm-upload',
//   templateUrl: 'confirm-upload.html',
// })
// export class UploadConfirmDialog {

//   errorMsg: string;
//   showError: boolean = false;
//   data_dir: string;
//   file: any;
//   path: string;
//   patient: any;
//   isLoading: boolean = false;
//   isSuccess: boolean = false;
//   successMsg: string = 'Success!';

//   btnOpts: MatProgressButtonOptions = {
//     active: false,
//     text: 'Yes',
//     spinnerSize: 19,
//     raised: true,
//     stroked: true,
//     flat: false,
//     fab: false,
//     buttonColor: 'primary',
//     spinnerColor: 'accent',
//     fullWidth: false,
//     disabled: false,
//     mode: 'indeterminate',
//     customClass: 'some-class',
//   };

//   constructor(
//     public dialogRef: MatDialogRef<UploadConfirmDialog>, public dialogParent: MatDialogRef<UploadRawData>, private auth: AuthenticationService, @Inject(MAT_DIALOG_DATA) public data: any) {
//     this.patient = data['patient'];
//     this.file = data['file'];
//     this.path = data['path'];
//   }

//   onClose(): void {
//     this.dialogRef.close();
//   }

//   readFromFile() {
//     this.isLoading = true;
//     this.auth.readFromFile(this.file['name'] + '$' + this.file['time'], this.path).subscribe(
//       response => {
//         let dataToSend = {
//           sensor_id: this.patient['sensor_id'],
//           patient_id: this.patient['patient_id'],
//           glucose: response['sugar_data']
//         };
//         this.auth.uploadDataToServer(dataToSend).subscribe(
//           res => {
//             this.isLoading = false;
//             this.showError = false;
//             this.isSuccess = true;
//             this.auth.deleteFile(this.file['name'] + '$' + this.file['time'], this.path).subscribe(
//               data => {
//               },
//               error => {
//                 console.log(error);
//               }
//             );
//             setTimeout(()=> {
//               this.dialogRef.close({'closeParent': true});
//             }, 2000)
//           },
//           error => {
//             this.isLoading = false;
//             this.showError = true;
//             if (error.status == 0 || error.status == 500) {
//               this.errorMsg = 'Something went wrong, please contact 7sugar admin!';
//             }
//             else {
//               this.errorMsg = error.error;
//             }
//             console.log(error);
//           }
//         );
//       },
//       error => {
//         if (error.status == 0 || error.status == 500) {
//           this.errorMsg = 'Something went wrong, please contact 7sugar admin!';
//         }
//         else {
//           this.errorMsg = error.error;
//         }
//         this.isLoading = false;
//         this.showError = true;
//         console.log(error);
//       }
//     );
//   }

// }


// @Component({
//   selector: 'upload-raw-data',
//   templateUrl: 'upload-raw-data.html',
// })
// export class UploadRawData {

//   newFileForm: FormGroup;
//   filepath: string;
//   errorMsg: string;
//   showError: boolean = false;
//   data_dir: string;
//   currentPage: number;
//   localfiles = [];
//   files = [];
//   serverUp: boolean = true;
//   isLoading: boolean = true;
//   serverErrorMsg: string;
//   patient: any;
//   showLoader: boolean = false;

//   constructor(
//     public dialogRef: MatDialogRef<UploadRawData>, private auth: AuthenticationService, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
//       this.newFileForm = new FormGroup({
//         'name': new FormControl('', Validators.compose([
//           Validators.required
//         ]))
//       });
//       this.patient = data;

//       this.getDataFiles();
//     }

//       openConfirmDialog(file): void {
//         this.showLoader = true;
//         const dialogRef = this.dialog.open(ConfirmUploadDialog, {
//           width: '300px',
//           data: {'patient': this.patient, 'file': file, 'path': this.data_dir}
//         });

//         dialogRef.afterClosed().subscribe(result => {
//           if (result != undefined) {
//             if (result['closeParent']) {
//               this.dialogRef.close();
//             }
//           }
//         });
//       }

//       pageChanged(event) {
//         this.currentPage = event;
//       }

//       changePageNumber(event) {
//         this.currentPage = 1;
//       }

//     getDataFiles() {
//       this.auth.getDataFiles().subscribe(
//         data => {
//           this.isLoading = false;
//           this.localfiles = data['files_list'];
//           this.data_dir = data['file_path'];
//           this.files = [];
//           for (let filename of this.localfiles) {
//             let temp = {
//               'name': filename.split('$')[0],
//               'time': filename.split('$')[1].replace('.json', ''),
//               'path': filename
//             }
//             this.files.push(temp);
//           }
//         },
//         error => {
//           this.isLoading = false;
//           this.serverUp = false;
//           this.serverErrorMsg = 'Something went wrong, please contact 7sugar admin!';
//         }
//       )
//     }

//   onClose(): void {
//     this.dialogRef.close();
//   }

// }



@Component({
  selector: 'patient-home',
  templateUrl: './patient-home.component.html',
  styleUrls: ['./patient-home.component.scss']
})

export class PatientHomeComponent implements OnInit {

  currentPage: number = 1;
  searchText;
  patients;
  showError: boolean = false;
  isLoading: boolean = true;
  errorMsg: string;
  reportUrl: string = 'https://reports.7sugar.com/report?';
  dietReportUrl: string = 'https://reports.7sugar.com/meals?'
  gmpReportUrl: string = 'https://reports.7sugar.com/gmp-report?'
  health_facilities: Array<number>;
  selectedPatient: any;
  patientID: string;
  reportDisabled = true;
  generatingReport = false;
  patientData;
  cgm_reports;
  isUploadLoading = false;

  btnOpts: MatProgressButtonOptions = {
    active: false,
    text: 'Report',
    spinnerSize: 19,
    raised: true,
    stroked: false,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    // customClass: 'loader-btn',
  };

  constructor(private auth: AuthenticationService, public dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.patientID = localStorage.getItem('user_id');

    this.getPatientReports();

    this.isLoading = false;
  }

  getPatientReports() {
    const dataToSend = {
      'user_id': this.patientID
    }
    this.auth.getPatientReports(dataToSend).subscribe(
      response => {
        this.patientData = response;
        this.cgm_reports = response['report_list'];
        console.log(this.cgm_reports)
        if (this.cgm_reports.length > 0) {
          console.log('reports found')
          this.reportDisabled = false;
        }
      },
      error => {

      }
    )
  }

  pageChanged(event) {
    this.currentPage = event;
  }

  changePageNumber(event) {
    this.currentPage = 1;
  }

  viewAvailableReports() {

    const dialogRef = this.dialog.open(ReportListComponent, {
      width: '600px',
      data: {
        'cgm_sensors': this.cgm_reports,
        'patient_id': this.patientID
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // if (result != undefined) {

      //   this.isLoading = false;
      //   this.generatingReport = true;
      //   setTimeout(() => {
      //     this.generatingReport = false;
      //   }, 30000)

      // }
    });

  }

  openUploadDialog(patient): void {
    this.isLoading = true;
    this.showError = false;
    this.auth.readFromReader().subscribe(
      response => {
        this.isLoading = false;
        if (response['source'] == 'freestyle_libre') {
          const dialogRef = this.dialog.open(UploadConfirmPatient, {
            width: '600px',
            data: {
              'sugar_data': response,
              'patient_id': this.patientID
            }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {

              this.isLoading = false;
              this.generatingReport = true;
              setTimeout(() => {
                this.getPatientReports();
                this.generatingReport = false;
              }, 30000)

            }
          });
        }
        else if (response['source'] == 'freestyle_libre_pro') {
          this.isUploadLoading = true;

          let dataToSend = {
            // sensor_id: this.patientID,
            user_id: this.patientID,
            sugar_data: response['glucose'],
            source: response['source'],
            patient_id: this.patientID,
            timezone: 'Asia/Kolkata',
            timestamp: Date.now(),
            user_agent: 'web',
          };
          this.auth.uploadDataToV1Server(dataToSend).subscribe(
            res => {
              this.isUploadLoading = false;
              this.isLoading = false;
              this.showError = false;
              this.generatingReport = true;

              // this.isSuccess = true;
              // this.successMsg = 'Data uploaded successfully! Your report will be generated.'

              setTimeout(() => {
                this.getPatientReports();
                this.generatingReport = false;
              }, 30000)
            },
            error => {
              this.isUploadLoading = false;
              this.showError = true;

              if (error.status == 0 || error.status == 500) {
                this.errorMsg = 'Something went wrong, please contact 7sugar admin!';
              }
              else {
                this.errorMsg = error.error;
              }
              console.log(error);
            }
          );
        }

        else {
          this.errorMsg = 'This sensor device not yet supported. Contact 7sugar team to raise requests for your sensor type';
          this.showError = true;
        }

        // let dataToSend = {
        //   // sensor_id: this.patientID,
        //   patient_id: this.patientID,
        //   glucose: response
        // };
        // this.auth.uploadDataToServer(dataToSend).subscribe(
        //   res => {
        //     this.isLoading = false;
        //     this.showError = false;
        //     this.isSuccess = true;
        //     setTimeout(()=> {
        //       this.dialogRef.close({'closeParent': true});
        //     }, 1200)
        //   },
        //   error => {
        //     this.isLoading = false;
        //     this.showError = true;

        //     if (error.status == 0 || error.status == 500) {
        //       this.errorMsg = 'Something went wrong, please contact 7sugar admin!';
        //     }
        //     else {
        //       this.errorMsg = error.error;
        //     }            
        //     console.log(error);
        //   }
        // );
      },
      error => {
        if (error.status == 0 || error.status == 500) {
          this.errorMsg = 'Something went wrong, please contact 7sugar admin!';
        }
        else {
          this.errorMsg = error.error;
        }
        this.isLoading = false;
        this.showError = true;
        console.log(error);
      }
    );
    // this.selectedPatient = patient;

  }

  // openUploadDialog(patient): void {
  //   this.selectedPatient = patient;
  //   const dialogRef = this.dialog.open(UploadRawData, {
  //     width: '550px',
  //     height: '560px',
  //     data: this.selectedPatient
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result != undefined) {

  //     }
  //   });
  // }

  // getPatients() {
  //   let data = this.health_facilities;
  //   this.auth.getPatients(data).subscribe(
  //     data => {
  //       this.patients = data['patients'];
  //       this.showError = false;
  //       this.isLoading = false;
  //     },
  //     error => {
  //       console.log(error);
  //       this.isLoading = false;
  //       this.showError = true;
  //       if (error.status == 0 || error.status == 500) {
  //         this.errorMsg = 'Something went wrong, please contact 7sugar admin!';
  //       }
  //       else {
  //         this.errorMsg = error.error;
  //       }
  //     }
  //   )
  // }

  logout() {
    localStorage.clear();
    this.router.navigate(['/']);
  }

  viewReport(patient) {
    window.open(this.reportUrl + 'patientID=' + patient.patient_id + '&sensorID=' + patient.sensor_id, '_blank')
  }

  // viewDietReport(patient) {
  //   window.open(this.dietReportUrl + 'profileID=' + patient.profile_id, '_blank')
  // }

  // viewGmpReport(patient) {
  //   console.log(patient);
  //   var end_date = new Date();
  //   var start_date = new Date().setDate(end_date.getDate() - 90);
  //   console.log(start_date);
  //   var end_date_str = end_date.toLocaleDateString();
  //   var start_date_str = new Date(1577764865335).toLocaleDateString();

  //   window.open(this.gmpReportUrl + 'profileID=' + patient.profile_id + '&start_date=' + start_date_str + ' 00:00&end_date=' + end_date_str + ' 23:59', '_blank')
  // }

}
