import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from './../_services/authentication.service';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: "gmp-modal-content",
  templateUrl: "gmp-modal.component.html",
})
export class GmpModalComponent {
    newFileForm: FormGroup;
    filepath: string;
    errorMsg: string;
    showError: boolean = false;
    data_dir: string;
    patient: any;
    isLoading: boolean = false;
    isSuccess: boolean = false;
    successMsg: string = 'Success!';
    range;
  
    btnOpts: MatProgressButtonOptions = {
      active: false,
      text: 'Yes',
      spinnerSize: 19,
      raised: true,
      stroked: true,
      flat: false,
      fab: false,
      buttonColor: 'primary',
      spinnerColor: 'accent',
      fullWidth: false,
      disabled: false,
      mode: 'indeterminate',
      customClass: 'some-class',
    };
  
    constructor(
      public dialogRef: MatDialogRef<GmpModalComponent>, private auth: AuthenticationService, @Inject(MAT_DIALOG_DATA) public data: any, fb: FormBuilder) {
      this.patient = data;

      this.range = fb.group({
        date: [{begin: new Date(), end: new Date()}]
      });
    }
  
    onClose(): void {
      this.dialogRef.close();
    }

    getGmpReport(days) {
        let end = new Date().setHours(0,0,0,0);
        console.log(end);
        let start = end - days * 86400000;
        this.dialogRef.close({'start': start, 'end': end});
    }

    getGmpfromDates() {
        console.log(this.range.value);
        let start = Date.parse(this.range.value.date.begin);
        let end = Date.parse(this.range.value.date.end);
        this.dialogRef.close({'start': start, 'end': end});
    }
  }
