import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PatientsComponent } from './patients/patients.component';
import { V1LoginComponent } from './v1-login/v1-login.component';
import { V1PatientsComponent } from './v1-patients/v1-patients.component';
import { UsertypeComponent} from './usertype/usertype.component';
import { PatientLoginComponent } from './patient-login/patient-login.component'
import { PatientHomeComponent } from './patient-home/patient-home.component'

const routes: Routes = [
  { path: '', component: UsertypeComponent},
  { path: 'patient/login', component: PatientLoginComponent},
  { path: 'patient/home', component: PatientHomeComponent},
  { path: 'caregiver/login', component: V1LoginComponent},
  { path: 'patients', component: V1PatientsComponent},
  // { path: 'usb', component: UsbComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
