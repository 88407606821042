import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from './../_services/authentication.service';
import { Router } from '@angular/router';

// var messages = require("./../proto/.js");

@Component({
  selector: 'app-login',
  templateUrl: './v1-login.component.html',
  styleUrls: ['./v1-login.component.scss']
})
export class V1LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  isDisabled = true;
  isError: boolean = false;
  errorMsg: string;
  returnUrl: string;
  loginData;
  isPhone: boolean = true;
  // passwordRegex = '^.{6,}$';
  emailRegex = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

    constructor(
        private formBuilder: FormBuilder,
        // private route: ActivatedRoute,
        private router: Router,
        private auth: AuthenticationService,
        // private alertService: AlertService
    ) {}

    ngOnInit() {
      this.loginForm = new FormGroup({
        email: new FormControl('', Validators.compose([
          Validators.pattern(this.emailRegex)
        ])),
        password: new FormControl('', Validators.compose([
          Validators.required,
          // Validators.pattern(this.passwordRegex)
        ])),
        country: new FormControl('+91'),
        mobile: new FormControl('')
      });

      // console.log(messages);
      // this.emailAuth.email = 'admin@7sugar.com';
      // this.emailAuth.password = '7sugar!23';
      // this.emailAuth.timestamp = 1234564567;
      // this.emailAuth.userAgent = 'web';
      // EmailAuthParams.encode(this.emailAuth);

      // var obj = admin.EmailAuthParams.fromObject({
      //   'email': 'admin@7sugar.com',
      //   'password': '7sugar!23',
      //   'timestamp': 1234567890,
      //   'userAgent': 'web'
      // });

      // var data = {
      //   'email': 'admin@7sugar.com',
      //   'password': '7sugar!23',
      //   'timestamp': 1234567890,
      //   'user_agent': 'web'
      // }

      // var obj = EmailAuthParams.fromJSON({
      //   'email': 'admin@7sugar.com',
      //   'password': '7sugar!23',
      //   'timestamp': 1234567890,
      //   'userAgent': 'web'
      // });

      // this.loginData = this.protobufService.serializeBinaryMessage(data);
      // console.log(this.loginData);
      
      // this.loginData = EmailAuthParams.encode(obj).finish();
      // console.log(this.loginData);
      // var xd = EmailAuthParams.decode(this.loginData);
      // this.loginData = admin.EmailAuthParams.encode(obj).finish();
      // console.log(this.loginData.constructor);
      // console.log(typeof(this.loginData))
      // console.log(admin.EmailAuthParams.decode(this.loginData));
    }

    toggleEmailPhone() {
      this.isPhone = !this.isPhone;
    }

    login(data) {
      this.submitted = true;
      if (this.loginForm.invalid) {
          return;
      }
      
      this.loading = true;
      this.isError = false;
      var dataToSend;
      if (this.isPhone) {
        dataToSend = {
          "mobile_number": data['country'] + data['mobile'],
          "password": data['password'],
          "timestamp": Date.now(),
          "user_agent": "web"
        }
      }
      else {
        dataToSend = {
          "email": data['email'],
          "password": data['password'],
          "timestamp": Date.now(),
          "user_agent": "web"
        }
      }


      this.auth.loginToV1(dataToSend).subscribe(
        data => {
          this.submitted = false;
          // let health_facilities = [];
          // if ('errors' in data ) {
          //   this.isError = true;
          //   this.errorMsg = data['errors'];
          // }
          // else {
          //   for (var hf of data['caregiver']['health_facilities']) {
          //     health_facilities.push(hf['id']);
          //   }
          //   localStorage.setItem('health_facilities', JSON.stringify({'health_facilities': health_facilities}))
          //   this.router.navigateByUrl('/patients');
          // }
          localStorage.setItem('user_id', data['user_id']);
          localStorage.setItem('device_id', data['device_id']);
          localStorage.setItem('auth_token', data['auth_token']);
          this.router.navigateByUrl('/patients');
        },
        error => {
          if (error.status == 400 || 401) {
            this.isError = true;
            this.errorMsg = 'Invalid credentials. Please enter correct details.'
            this.submitted = false;
            console.log(error);
          }
          else {
            this.isError = true;
            this.errorMsg = 'Something went wrong, please try again or contact admin!'
            this.submitted = false;
            console.log(error);
          }
        });
      }

}
