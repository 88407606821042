import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent {
  isLoading: boolean = false;
  isSuccess: boolean = false;
  successMsg: string = 'Success!';
  sensor_data: any;
  patientID;
  glucose_data;
  isUploadLoading = false;
  cgm_reports;
  reportUrl: string = 'https://reports.7sugar.com/v1-report?';


  // btnOpts: MatProgressButtonOptions = {
  //   active: false,
  //   text: 'Yes',
  //   spinnerSize: 19,
  //   raised: true,
  //   stroked: true,
  //   flat: false,
  //   fab: false,
  //   buttonColor: 'primary',
  //   spinnerColor: 'accent',
  //   fullWidth: false,
  //   disabled: false,
  //   mode: 'indeterminate',
  //   customClass: 'some-class',
  // };

  constructor(
    public dialogRef: MatDialogRef<ReportListComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    const patientData = data;
    this.patientID = data['patient_id']

    this.cgm_reports = data['cgm_sensors'].map(sensor =>
      Object.assign({}, sensor, {
        start_timestamp: new Date(sensor['start']).toLocaleDateString(),
        end_timestamp: new Date(sensor['end']).toLocaleDateString()
      }))
    console.log(this.glucose_data);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  viewReport(report) {
      var dataToSend = {
        "user_id": this.patientID,
        "user_token": localStorage.getItem('auth_token'),
        "device_id": localStorage.getItem('device_id'),
        "timestamp": Date.now(),
        "user_agent": "web"
      }
      const report_id = report['report_id']
  
      window.open(this.reportUrl + 'id=' + dataToSend['user_id'] + '&device=' + dataToSend['device_id'] + "&patient=" + this.patientID + "&slug=" + dataToSend['user_token'] + "&report_id=" + report_id, '_blank')
  
  }

}
