import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationService } from './_services/authentication.service';

import { HttpClientModule } from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { MatCardModule,   
  MatFormFieldModule,
  MatInputModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxLoadingModule } from 'ngx-loading';

import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';

// import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PatientsComponent, UploadRawData, ConnectToReader, ConfirmUploadDialog } from './patients/patients.component';
import { V1LoginComponent } from './v1-login/v1-login.component';
import { V1PatientsComponent, ConnectToReaderV1, ConfirmV1UploadDialog, UploadRawDataV1 } from './v1-patients/v1-patients.component';
import { GmpModalComponent } from './v1-patients/gmp-modal.component';
import { PatientLoginComponent } from './patient-login/patient-login.component';
import { UsertypeComponent } from './usertype/usertype.component';
import { PatientHomeComponent } from './patient-home/patient-home.component';
import { UploadConfirmPatient } from './upload-confirm-libre/upload-confirm-libre';
import { ReportListComponent } from './report-list/report-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PatientsComponent,
    UploadRawData,
    ConnectToReader,
    ConnectToReaderV1,
    ConfirmV1UploadDialog,
    UploadRawDataV1,
    ConfirmUploadDialog,
    V1LoginComponent,
    V1PatientsComponent,
    GmpModalComponent,
    PatientLoginComponent,
    UsertypeComponent,
    PatientHomeComponent,
    UploadConfirmPatient,
    ReportListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatCardModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    MatProgressSpinnerModule,
    MatProgressButtonsModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    NgxLoadingModule.forRoot({})
  ],
  providers: [AuthenticationService],
  bootstrap: [AppComponent],
  entryComponents: [UploadRawData, UploadConfirmPatient, ReportListComponent, ConnectToReader, ConfirmUploadDialog, UploadRawDataV1, ConnectToReaderV1, ConfirmV1UploadDialog, GmpModalComponent]
})
export class AppModule { }
