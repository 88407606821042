import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from './../_services/authentication.service';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GmpModalComponent } from './gmp-modal.component';
import { ReportListComponent } from '../report-list/report-list.component';
import { UploadConfirmPatient } from '../upload-confirm-libre/upload-confirm-libre';

// import { NgbModal } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'confirm-upload',
  templateUrl: 'confirm-upload.html',
})
export class ConfirmV1UploadDialog {

  errorMsg: string;
  showError: boolean = false;
  data_dir: string;
  file: any;
  path: string;
  patient: any;
  isLoading: boolean = false;
  isSuccess: boolean = false;
  successMsg: string = 'Success!';

  btnOpts: MatProgressButtonOptions = {
    active: false,
    text: 'Yes',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'some-class',
  };

  constructor(
    public dialogRef: MatDialogRef<ConfirmV1UploadDialog>, public dialogParent: MatDialogRef<UploadRawDataV1>, private auth: AuthenticationService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.patient = data['patient'];
    console.log("confirm ", this.patient)
    this.file = data['file'];
    this.path = data['path'];
  }

  onClose(): void {
    this.dialogRef.close();
  }

  readFromFile() {
    this.isLoading = true;
    this.auth.readFromFile(this.file['name'] + '$' + this.file['time'], this.path).subscribe(
      response => {

        console.log("cgm loading 2........")
        let dataToSend = {
          'user_id': localStorage.getItem('user_id'),
          'user_token': localStorage.getItem('auth_token'),
          'device_id': localStorage.getItem('device_id'),
          'patient_id': this.patient['user_id'],
          'timezone': 'Asia/Kolkata',
          'timestamp': Date.now(),
          'user_agent': 'web',
          'sugar_data': response['sugar_data']
        };
        this.auth.uploadDataToV1Server(dataToSend).subscribe(
          res => {
            this.isLoading = false;
            this.showError = false;
            this.isSuccess = true;
            this.auth.deleteFile(this.file['name'] + '$' + this.file['time'], this.path).subscribe(
              data => {
              },
              error => {
                console.log(error);
              }
            );
            setTimeout(() => {
              this.dialogRef.close({ 'closeParent': true, 'patient': this.patient });
            }, 2000)
          },
          error => {
            this.isLoading = false;
            this.showError = true;
            if (error.status == 0 || error.status == 500) {
              this.errorMsg = 'Something went wrong, please contact 7sugar admin!';
            }
            else {
              this.errorMsg = error.error;
            }
            console.log(error);
          }
        );
      },
      error => {
        if (error.status == 0 || error.status == 500) {
          this.errorMsg = 'Something went wrong, please contact 7sugar admin!';
        }
        else {
          this.errorMsg = error.error;
        }
        this.isLoading = false;
        this.showError = true;
        console.log(error);
      }
    );
  }

}

@Component({
  selector: 'connect-to-reader',
  templateUrl: 'connect-to-reader.html',
})
export class ConnectToReaderV1 {
  newFileForm: FormGroup;
  filepath: string;
  errorMsg: string;
  showError: boolean = false;
  data_dir: string;
  patient: any;
  isLoading: boolean = false;
  isSuccess: boolean = false;
  successMsg: string = 'Success!';

  btnOpts: MatProgressButtonOptions = {
    active: false,
    text: 'Yes',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'some-class',
  };

  constructor(
    public dialogRef: MatDialogRef<ConnectToReaderV1>, private auth: AuthenticationService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.patient = data;
  }

  onClose(): void {
    this.dialogRef.close();
  }


  readFromReader() {
    this.isLoading = true;
    this.showError = false;
    this.auth.readFromReader().subscribe(
      response => {

        if (!('source' in response) || (response['source'] == 'freestyle_libre_pro')) {
          let dataToSend = {
            'user_id': localStorage.getItem('user_id'),
            'user_token': localStorage.getItem('auth_token'),
            'device_id': localStorage.getItem('device_id'),
            'patient_id': this.patient['user_id'],
            'timezone': 'Asia/Kolkata',
            'timestamp': Date.now(),
            'user_agent': 'web',
            'sugar_data': response['glucose'],
            'source': response['source']
          };

          this.auth.uploadDataToV1Server(dataToSend).subscribe(
            res => {
              this.isLoading = false;
              this.showError = false;
              this.isSuccess = true;
              setTimeout(() => {
                this.dialogRef.close({ 'closeParent': true, 'patient': this.patient });
              }, 1200)
            },
            error => {
              this.isLoading = false;
              this.showError = true;

              if (error.status == 0 || error.status == 500) {
                this.errorMsg = 'Something went wrong, please contact 7sugar admin!';
              }
              else {
                this.errorMsg = error.error;
              }
              console.log(error);
            }
          );
        }
        // else if (response['glucose'].length == 1) {

        //   let dataToSend = {
        //     'user_id': localStorage.getItem('user_id'),
        //     'user_token': localStorage.getItem('auth_token'),
        //     'device_id': localStorage.getItem('device_id'),
        //     'patient_id': this.patient['user_id'],
        //     'timezone': 'Asia/Kolkata',
        //     'timestamp': Date.now(),
        //     'user_agent': 'web',
        //     'sugar_data': response['glucose'][0]
        //   };

        //   this.auth.uploadDataToV1Server(dataToSend).subscribe(
        //     res => {
        //       this.isLoading = false;
        //       this.showError = false;
        //       this.isSuccess = true;
        //       setTimeout(() => {
        //         this.dialogRef.close({ 'closeParent': true, 'patient': this.patient });
        //       }, 1200)
        //     },
        //     error => {
        //       this.isLoading = false;
        //       this.showError = true;

        //       if (error.status == 0 || error.status == 500) {
        //         this.errorMsg = 'Something went wrong, please contact 7sugar admin!';
        //       }
        //       else {
        //         this.errorMsg = error.error;
        //       }
        //       console.log(error);
        //     }
        //   );
        // }

        else {
          console.log('multiple sensors found...........')

          setTimeout(() => {
            this.dialogRef.close({ 'closeParent': true, 'patient': this.patient, 'multiple_sensors': true, 'sugar_data': response });
            console.log('closing connect to reader dialog...');
          }, 500)
        }
      },
      error => {
        if (error.status == 0 || error.status == 500) {
          this.errorMsg = 'Something went wrong, please contact 7sugar admin!';
        }
        else {
          this.errorMsg = error.error;
        }
        this.isLoading = false;
        this.showError = true;
        console.log(error);
      }
    );
  }
}


@Component({
  selector: 'upload-raw-data',
  templateUrl: 'upload-raw-data.html',
})
export class UploadRawDataV1 {

  newFileForm: FormGroup;
  filepath: string;
  errorMsg: string;
  showError: boolean = false;
  data_dir: string;
  currentPage: number;
  localfiles = [];
  files = [];
  serverUp: boolean = true;
  isLoading: boolean = true;
  serverErrorMsg: string;
  patient: any;
  showLoader: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UploadRawDataV1>, private auth: AuthenticationService, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    this.newFileForm = new FormGroup({
      'name': new FormControl('', Validators.compose([
        Validators.required
      ]))
    });
    this.patient = data;
    console.log(this.patient);

    this.getDataFiles();
  }

  openConfirmDialog(file): void {
    this.showLoader = true;
    const dialogRef = this.dialog.open(ConfirmV1UploadDialog, {
      width: '300px',
      data: { 'patient': this.patient, 'file': file, 'path': this.data_dir }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result['closeParent']) {
          this.dialogRef.close(result['patient']);
        }
      }
    });
  }

  pageChanged(event) {
    this.currentPage = event;
  }

  changePageNumber(event) {
    this.currentPage = 1;
  }

  getDataFiles() {
    this.auth.getDataFiles().subscribe(
      data => {
        this.isLoading = false;
        this.localfiles = data['files_list'];
        this.data_dir = data['file_path'];
        this.files = [];
        for (let filename of this.localfiles) {
          let temp = {
            'name': filename.split('$')[0],
            'time': filename.split('$')[1].replace('.json', ''),
            'path': filename
          }
          this.files.push(temp);
        }
      },
      error => {
        this.isLoading = false;
        this.serverUp = false;
        this.serverErrorMsg = 'Something went wrong, please contact 7sugar admin!';
      }
    )
  }

  onClose(): void {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'app-patients',
  templateUrl: './v1-patients.component.html',
  styleUrls: ['./v1-patients.component.scss']
})

export class V1PatientsComponent implements OnInit {

  currentPage: number = 1;
  searchText;
  patients;
  showError: boolean = false;
  isLoading: boolean = true;
  errorMsg: string;
  reportUrl: string = 'https://reports.7sugar.com/v1-report?';
  // reportUrl: string = 'http://localhost:4200/v1-report?';
  dietReportUrl: string = 'https://reports.7sugar.com/meals?'
  health_facilities: Array<number>;
  selectedPatient: any;

  btnOpts: MatProgressButtonOptions = {
    active: false,
    text: 'Report',
    spinnerSize: 19,
    raised: true,
    stroked: false,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    // customClass: 'loader-btn',
  };

  constructor(private auth: AuthenticationService, public dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.health_facilities = JSON.parse(localStorage.getItem('health_facilities'));
    this.getPatients();
  }

  pageChanged(event) {
    this.currentPage = event;
  }

  changePageNumber() {
    this.currentPage = 1;
  }

  public openGmpModal(patient) {
    const dialogRef = this.dialog.open(GmpModalComponent, {
      width: '400px',
      data: patient
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        console.log(result);
        patient['start_date'] = result['start'];
        patient['end_date'] = result['end'];
        this.viewGmpReport(patient);

      }
    });
    // const modalRef = this.modalService.open(GmpModalComponent,{size:"lg",centered:true});
  }

  openReaderDialog(patient): void {
    this.selectedPatient = patient;
    const dialogRef = this.dialog.open(ConnectToReaderV1, {
      width: '300px',
      data: this.selectedPatient
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('reader dialog closed...')
      if (result != undefined) {
        console.log('multiple key??')
        if ('multiple_sensors' in result) {
          console.log('key present....')
          console.log(result);
          const dialogRef = this.dialog.open(UploadConfirmPatient, {
            width: '600px',
            data: {
              'sugar_data': result['sugar_data'],
              'patient_id': patient['user_id']
            }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {

              this.isLoading = false;

              this.patients[patient['index']]['cgm_loading'] = true;
              console.log(" cgm loading......");
              setTimeout(() => {
                window.location.reload()
                this.patients[patient['index']]['cgm_loading'] = false;
                this.patients[patient['index']]['cgm_report'] = true;
                console.log(" cgm donee.....................")
              }, 30000);

            }
          });
        }

        console.log(result);

        this.patients[patient['index']]['cgm_loading'] = true;
        console.log(" cgm loading......");
        setTimeout(() => {
          this.patients[patient['index']]['cgm_loading'] = false;
          this.patients[patient['index']]['cgm_report'] = true;
          console.log(" cgm donee.....................")
        }, 30000);

      }
    });
  }

  openUploadDialog(patient): void {
    this.selectedPatient = patient;
    const dialogRef = this.dialog.open(UploadRawDataV1, {
      width: '550px',
      height: '560px',
      data: this.selectedPatient
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        console.log(result);

        this.patients[patient['index']]['cgm_loading'] = true;
        console.log(" cgm loading......");
        setTimeout(() => {
          this.patients[patient['index']]['cgm_loading'] = false;
          this.patients[patient['index']]['cgm_report'] = true;
          console.log(" cgm donee.....................")
        }, 30000);

      }
    });
  }

  getPatients() {
    // let data = this.health_facilities;
    var dataToSend = {
      "user_id": localStorage.getItem('user_id'),
      "user_token": localStorage.getItem('auth_token'),
      "device_id": localStorage.getItem('device_id'),
      "timestamp": Date.now(),
      "user_agent": "web"
    }
    this.auth.getV1Patients(dataToSend).subscribe(
      data => {
        this.patients = data['patients_list'];
        this.patients.forEach((element, index) => {
          element['cgm_loading'] = false;
          element['index'] = index;
        });
        this.showError = false;
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
        this.showError = true;
        if (error.status == 0 || error.status == 500) {
          this.errorMsg = 'Something went wrong, please contact 7sugar admin!';
        }
        else {
          this.errorMsg = error.error;
        }
      }
    )
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/']);
  }

  viewReport(patient) {
    if (!('cgm_sensors' in patient['reports_list'])) {
      {
        var dataToSend = {
          "user_id": localStorage.getItem('user_id'),
          "user_token": localStorage.getItem('auth_token'),
          "device_id": localStorage.getItem('device_id'),
          "timestamp": Date.now(),
          "user_agent": "web"
        }
        window.open(this.reportUrl + 'id=' + dataToSend['user_id'] + '&device=' + dataToSend['device_id'] + "&patient=" + patient['user_id'] + "&slug=" + dataToSend['user_token'], '_blank')
      }
    }
    else if (patient['reports_list']['cgm_sensors'].length > 1) {
      const dialogRef = this.dialog.open(ReportListComponent, {
        width: '600px',
        data: {
          'cgm_sensors': patient['reports_list']['cgm_sensors'],
          'patient_id': patient['user_id']
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        // if (result != undefined) {

        //   this.isLoading = false;
        //   this.generatingReport = true;
        //   setTimeout(() => {
        //     this.generatingReport = false;
        //   }, 30000)

        // }
      });
    }
    else {
      var dataToSend = {
        "user_id": localStorage.getItem('user_id'),
        "user_token": localStorage.getItem('auth_token'),
        "device_id": localStorage.getItem('device_id'),
        "timestamp": Date.now(),
        "user_agent": "web"
      }
      const report_id = patient['reports_list']['cgm_sensors'][0]['report_id']

      window.open(this.reportUrl + 'id=' + dataToSend['user_id'] + '&device=' + dataToSend['device_id'] + "&patient=" + patient['user_id'] + "&slug=" + dataToSend['user_token'] + "&report_id=" + report_id, '_blank')
    }
  }

  viewGmpReport(patient) {
    var dataToSend = {
      "user_id": localStorage.getItem('user_id'),
      "user_token": localStorage.getItem('auth_token'),
      "device_id": localStorage.getItem('device_id'),
      "timestamp": Date.now(),
      "user_agent": "web",
    }

    window.open("https://reports.7sugar.com/gmp-report?" + "start_date=" + patient['start_date'] + "&end_date=" + patient['end_date'] + '&id=' + dataToSend['user_id'] + '&device=' + dataToSend['device_id'] + "&patient=" + patient['user_id'] + "&slug=" + dataToSend['user_token'], '_blank')
  }

  viewDietReport(patient) {
    window.open(this.dietReportUrl + 'profileID=' + patient.profile_id, '_blank')
  }

}
