import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from './../_services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  isDisabled = true;
  isError: boolean = false;
  errorMsg: string;
  returnUrl: string;
  // passwordRegex = '^.{6,}$';
  emailRegex = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

    constructor(
        private formBuilder: FormBuilder,
        // private route: ActivatedRoute,
        private router: Router,
        private auth: AuthenticationService,
        // private alertService: AlertService
    ) {}

    ngOnInit() {
      this.loginForm = new FormGroup({
        email: new FormControl('', Validators.compose([
          Validators.required,
          Validators.pattern(this.emailRegex)
        ])),
        password: new FormControl('', Validators.compose([
          Validators.required,
          // Validators.pattern(this.passwordRegex)
        ])),
      });
    }

    login(data) {
      this.submitted = true;
      if (this.loginForm.invalid) {
          return;
      }
      data = {
        'session': data
      }
      this.loading = true;
      this.isError = false;
      this.auth.login(data).subscribe(
        data => {
          this.submitted = false;
          let health_facilities = [];
          if ('errors' in data ) {
            this.isError = true;
            this.errorMsg = data['errors'];
          }
          else {
            for (var hf of data['caregiver']['health_facilities']) {
              health_facilities.push(hf['id']);
            }
            localStorage.setItem('health_facilities', JSON.stringify({'health_facilities': health_facilities}))
            this.router.navigateByUrl('/patients');
          }
        },
        error => {
          this.isError = true;
          this.errorMsg = 'Something went wrong, please try again or contact admin!'
          this.submitted = false;
          console.log(error);
        });
      }

}
